<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <div class="flex-end">
          <el-button @click="$router.push({path:'/product'})">
            返回
          </el-button>
          <el-button
              v-if="importPerm"
              size="small"
              class="mr10"
              type="primary"
              icon="el-icon-upload"
              @click="importDialogShow"
          >
            导入
          </el-button>
          <el-button
              v-if="exportPerm"
              size="small"
              type="primary"
              icon="el-icon-download"
              @click="exportScence"
          >
            导出
          </el-button>
          <el-button
            v-if="savePerm"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="$router.push({path: '/sceneSave', query: { pId: productId}})"
          >
            新增
          </el-button>
        </div>
      </div>
      <div class="table-wrap">
        <FilterSelectList
          :list-query.sync="listQuery"
          :select-list.sync="selectList"
          @handleFilter="handleFilter"
        />
        <el-table
          v-loading="loading"
          fit
          highlight-current-row
          :data="tableData"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column
            prop="name"
            :show-overflow-tooltip="true"
            label="场景名称"
          />
          <el-table-column
            prop="describe"
            :show-overflow-tooltip="true"
            label="场景描述"
          />
          <el-table-column
            prop="serviceArea"
            :show-overflow-tooltip="true"
            label="场景类型"
          >

            <template slot-scope="scope">
              {{ sceneTypeObj[scope.row.sceneType] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="serviceArea"
            :show-overflow-tooltip="true"
            label="服务地区"
          />
          <el-table-column
            width="120"
            prop="uptime"
            label="上线时间"
          >
            <template slot-scope="scope">
              {{ formatDateStr(scope.row.uptime) }}
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            prop="uptime"
            label="是否用于隐私声明"
          >
            <template slot-scope="scope">
              {{ scope.row.isUseStatement ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="informationProtectionMeasures"
            :show-overflow-tooltip="true"
            label="规划的信息保护措施"
          />
          <el-table-column
            prop="isEnforce"
            :show-overflow-tooltip="true"
            label="保护措施是否在实施"
          >
            <template slot-scope="scope">
              {{ scope.row.isEnforce ? '是' : '否' }}
            </template>
          </el-table-column>

          <el-table-column
            prop="userEndAge"
            :show-overflow-tooltip="true"
            label="服务年龄段"
          >
            <template slot-scope="{row}">
              <span v-if="row.userBeginAge !==0 && row.userEndAge !== 0">{{ row.userBeginAge }} 至 {{ row.userEndAge }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120px"
          >
            <template slot-scope="{row}">
              <el-link
                v-show="updatePerm"
                class="mr10"
                :underline="false"
                type="primary"
                @click="$router.push({path: '/sceneSave', query: { id: row.id, pId: productId}})"
              >
                <el-tooltip
                  content="修改"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link
                v-show="delPerm"
                class="mr10"
                type="primary"
                @click="delProduct(row.id)"
              >
                <el-tooltip
                  content="删除"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <PageQuery
          v-show="total>0"
          :total="total"
          :cache-name="'listQuery21'"
          :update-state="'UPDATE_LISTQUERY21'"
          :list-query.sync="listQuery"
          :init-list-query.sync="initListQuery"
          @pagination="getList"
        />
        <ImportScence ref="importScence"  @handleFilter="handleFilter"/>
      </div>
    </b-card>
  </div>
</template>

<script>
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import pagination from '@core/components/pagination/Pagination.vue'
import ImportScence from '@/views/enterprise/product/ImportScence.vue'
import { productScenePage, deleteSceneById, ExportProduct, ExportProductScence } from '@/api/enterprise/product'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'

export default {
  components: {
    pagination,
    FilterSelectList,
    TableHeaderFilterDropdown,
    ImportScence,
  },
  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      OrderField: '',
      order: '',
    }
    return {
      sceneTypeObj: {
        1: '基本业务场景',
        2: '拓展业务场景',
      },
      selectList: [],
      importPerm: false,
      savePerm: false,
      updatePerm: false,
      readPerm: false,
      delPerm: false,
      scenarioPerm: false,
      exportPerm: false,
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      productId: this.$route.query.id,
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.importPerm = verifyBtnPerm(btnPerms, 'product-6')
      this.savePerm = verifyBtnPerm(btnPerms, 'product-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'product-4')
      this.readPerm = verifyBtnPerm(btnPerms, 'product-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'product-5')
      this.scenarioPerm = verifyBtnPerm(btnPerms, 'product-7')
      this.exportPerm = verifyBtnPerm(btnPerms, 'product-8')
      this.scenarioPerm = true
    })
    const cacheQuery = this.$store.state.pageQuery.listQuery21
    this.listQuery = { ...cacheQuery }
    this.getList()
  },
  mounted() {
    this.navActiveInit(1, '业务管理', '企业管理')
  },
  destroyed() {
    this.navActiveInit(2, '业务管理', '企业管理', '/product')
  },
  methods: {
    exportScence() {
      ExportProductScence(this.productId)
    },
    importDialogShow() {
      this.$refs.importScence.dialogVisible = true
    },
    delProduct(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        deleteSceneById(this.productId, id).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.dialogVisible = false
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      this.listQuery.status = this.listQuery.status ? this.listQuery.status : 0
      this.listQuery.technicalDirectorId = this.listQuery.technicalDirectorId ? this.listQuery.technicalDirectorId : 0
      this.listQuery.productManagerId = this.listQuery.productManagerId ? this.listQuery.productManagerId : 0
      this.listQuery.productType = this.listQuery.productType ? this.listQuery.productType : 0
      productScenePage(this.productId, this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
